import React from "react";
import DataArea from "./DataArea";
import "../styles/Main.css";

function Main () {
    return (
      <>
        <DataArea />
      </>
    );
  };

export default Main;
